import React from 'react';
import { graphql } from 'gatsby';
import ReactMarkdown, { ReactMarkdownPropsBase } from 'react-markdown';
import breaks from 'remark-breaks';
import toc from 'remark-toc';
import styled from '@emotion/styled';

import { transformTextBreak, EmbedRenderer, HeadingRenderer, LinkRenderer } from '../../utils/markdown-renderer';
import { useBreakpoints } from '../../hooks/useBreakpoints';

const contentWidths = {
  body_text: '635px',
  body_citation: '760px',
  body_text_xl: '960px',
  full_width: undefined,
};

export type AlignedMarkdownProps = {
  markdown?: string;
  align?: 'left' | 'right' | 'center' | 'justify';
  className?: string;
  addTableOfContent?: boolean;
  maxWidth?: keyof typeof contentWidths;
};

const StyledReactMarkdown = styled(ReactMarkdown)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  pre {
    margin: 0;
    padding-bottom: ${({ theme }) => theme.spacing.editorialContent};
  }

  a {
    color: ${({ theme }) => theme.color.palette['base-purple-2']};
  }

  ul,
  ol {
    padding: 0;
    margin: 0 0 0 ${({ theme }) => theme.spacing.editorialContent};
  }

  blockquote {
    margin: 0;
  }
`;

export const AlignedMarkdown: React.FC<AlignedMarkdownProps> = props => {
  const { isDesktop } = useBreakpoints();

  const style = {
    textAlign: props.align,
    ...(props.maxWidth && isDesktop && { maxWidth: contentWidths[props.maxWidth], margin: '0 auto' }),
  };

  const text = transformTextBreak(props.markdown, !isDesktop);

  // TODO : fix this
  const plugins: ReactMarkdownPropsBase['plugins'] = [breaks] as ReactMarkdownPropsBase['plugins'];
  const renderers: ReactMarkdownPropsBase['renderers'] = {
    paragraph: EmbedRenderer,
    link: LinkRenderer,
  };

  if (props?.addTableOfContent) {
    plugins?.push([toc, { maxDepth: 3 }]);
    renderers.heading = HeadingRenderer;
  }

  return (
    <StyledReactMarkdown plugins={plugins} renderers={renderers} className={props.className} css={style}>
      {text}
    </StyledReactMarkdown>
  );
};

AlignedMarkdown.defaultProps = {
  align: 'left',
  markdown: '',
  addTableOfContent: false,
};

export const alignMarkdownFragment = graphql`
  fragment AlignedMarkdown on cms_ComponentComponentAlignedMarkdown {
    text
    text_alignment_md: text_alignment
    content_width
  }
`;
