import React from 'react';

import styled from '@emotion/styled';

export type SectionProps = {
  marginPreset?: 'all' | 'sides' | 'bottom' | 'top' | 'top-bottom' | 'top-sides' | 'none';
  firstChildMarginPreset?: 'all' | 'bottom' | 'bottom-sides' | 'none';
  background?: string;
  id?: string;
};

const SectionWrapper = styled.section<SectionProps>`
  ${({ background }) => background && `background-color: ${background};`}

  ${({ marginPreset, theme }) => {
    const { sectionGap, body, bodyMobile } = theme.spacing;

    switch (marginPreset) {
      case 'all':
        return `
          padding: ${sectionGap} ${bodyMobile};
          @media screen and (min-width: ${theme.breakpoint.desktop}) {
            padding: ${sectionGap} ${body};
          }
        `;

      case 'top':
        return `padding: ${sectionGap} 0 0 0;`;

      case 'bottom':
        return `padding: 0 0 ${sectionGap};`;

      case 'sides':
        return `
          padding: 0 ${bodyMobile};
          @media screen and (min-width: ${theme.breakpoint.desktop}) {
            padding: 0 ${body};
          }
        `;

      case 'top-bottom':
        return `padding: ${sectionGap} 0;`;

      case 'top-sides':
        return `
          padding: ${sectionGap} ${bodyMobile} 0 ${bodyMobile};
          @media screen and (min-width: ${theme.breakpoint.desktop}) {
            padding: ${sectionGap} ${body} 0 ${body};
          }`;

      case 'none':
        return '';
    }
  }}

  > *:first-child {
    ${({ firstChildMarginPreset, theme }) => {
      const { sectionGap, sectionGapMobile, body, bodyMobile } = theme.spacing;

      switch (firstChildMarginPreset) {
        case 'all':
          return `
             padding: ${sectionGapMobile} ${bodyMobile} 0;
             @media screen and (min-width: ${theme.breakpoint.desktop}) {
                 padding: ${sectionGap} ${body} 0;
             }
             margin-bottom: 50px;
          `;

        case 'bottom-sides':
          return `
             padding: 0 ${bodyMobile};
             @media screen and (min-width: ${theme.breakpoint.desktop}) {
                 padding: 0 ${body};
             }
             margin-bottom: 50px;
          `;

        case 'bottom':
          return `margin-bottom: 50px`;

        case 'none':
          return '';
      }
    }}
  }
`;

export const Section: React.FC<SectionProps> = props => {
  return <SectionWrapper {...props}>{props.children}</SectionWrapper>;
};

Section.defaultProps = {
  marginPreset: 'none',
  firstChildMarginPreset: 'none',
};
